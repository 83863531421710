$(document).ready(function(){
    FastClick.attach(document.body);

  if($('body').data('template') == 'fashion'){
      $('.project-image').each(function(){
        let rand_deg = Math.floor( Math.random() * 60) - 30,
            $pi = $(this);

            //$pi.css('transform', `rotate(${rand_deg}deg)`);

          $pi.mouseout(function(){
            let rand_deg = Math.floor( Math.random() * 60) - 30
            //$pi.css('transform', `rotate(${rand_deg}deg)`);
          });
      })
  }
  /*
  if($('body').data('template') == 'home'){
    let $window = $(window),
        $titleScreen = $('#TitleScreen'),
        $header = $('#Header');
    $('main').prepend($('#Dummy'))
    $titleScreen.on('click', function(){
        $('body, html').stop().animate({scrollTop: $window.height() * 2 }, 600);
    }).on('mouseover', function(){
        $('body').addClass('title-h1-over');
    }).on('mouseout', function(){
        $('body').removeClass('title-h1-over');
    });
    $window.on('scroll', function(){
        let st = $window.scrollTop(),
            op = (($window.height() - st)/$window.height() * 2);
        $titleScreen.css('opacity', op);
        $header.css('opacity', 1 - op);
    });
  }
  */
  
  $('#Header').on('mouseover', function(){
        //$('body').addClass('header-over');
  }).on('mouseout', function(){
        //$('body').removeClass('header-over');
  });

  if($('#Top').length > 0) {
      $('#Top').on('click', function(){
          $('body, html').animate({scrollTop:0}, 800);
      });
  }

  if($('.rellax').length > 0)
  var rellax = new Rellax('.rellax');
  let subTimerIn,
  subTimerOut;

  $('.subnav > li > a').on('mouseover', function(){
    $('.subsubnav').css('display', '');
  }).on('mouseout', function(){

  });

    $('.subnav > li').on('mouseover', function(){
        $(this).addClass('over');
        $(this).find('.subsubnav').css('display', 'inline-block');
        if($('.subnav .over .subsubnav').hasClass('over')){
            clearTimeout(subTimerOut);
        }
    }).on('mouseout', function(){
        $(this).removeClass('over');
        
    });

    $('.subsubnav').on('mouseover', function(e){
        $('.subsubnav').css('display', '');
        $(this).addClass('over');
        $(this).css('display', 'inline-block');
    }).on('mouseout', function(){
        $(this).removeClass('over');
        let $this = $(this);
        subTimerOut = setTimeout(function(){
            $this.css('display', '');
        }, 450);
    });

    /*
    

    $('.subsubnav').on('mouseover', function(e){
        let $sub = $(this);
        clearTimeout(subTimerOut);
        clearTimeout(subTimerIn);
        subTimerIn = setTimeout(function(){
            console.log($sub.parent())
            if($sub.parent().hasClass('over')){
                $sub.css('display', 'inline-block');
            }
        }, 50)
    }).on('mouseout', function(){
        let $sub = $(this);
        subTimerOut = setTimeout(function(){
            //$sub.css('display', '');
        }, 500);
    });
    */


});